@import 'styles/breakpoints';

.questions-card {
  margin-bottom: var(--spacing-600);
}

.footer {
  display: flex;
  align-items: center;
  gap: var(--spacing-400);

  @include until($widthMobile) {
    flex-direction: column;
  }
}

.phone {
  color: var(--text-default);
}
