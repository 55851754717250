@import 'styles/breakpoints';

ul.info {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  svg {
    color: var(--text-primary);
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-top: 2px;
  }

  li {
    display: flex;
    gap: var(--spacing-200);
    align-items: flex-start;
    font-size: var(--text-sm-font-size);
    line-height: var(--text-sm-line-height);
  }
}
