@import 'styles/breakpoints';

.root {
  border-radius: var(--radius-sm);
  background-color: var(--white);
  border: 1px solid var(--border-subdued);
}

.sticky {
  position: sticky;
  top: var(--spacing-900);

  // Support 13inch screens where the viewport has less height because of a visible mac appbar.
  @media screen and (min-height: 600px) and (max-height: 720px) {
    top: var(--spacing-700);
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
  padding: var(--spacing-500);
  border-bottom: 1px solid var(--border-subdued);

  &.no-padding {
    padding: 0;
  }
}

.header-title {
  font-size: var(--heading-xs-font-size);
  line-height: var(--heading-xs-line-height);
}

.body {
  padding: var(--spacing-500);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-500);

  &.no-padding {
    padding: 0;
  }
}

.panel-selection {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.panel-selection-title {
  font-size: var(--text-md-font-size);
  line-height: var(--text-md-line-height);
  font-weight: 400;
}

.buttons {
  display: grid;
  gap: var(--spacing-400);
  grid-auto-flow: column;
  grid-template-rows: auto;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  align-items: stretch;
}

.amount-button {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--border-subdued);
  border-radius: var(--radius-sm);
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1/1;

  font-size: var(--text-md-font-size);
  font-weight: 400;
  line-height: 1;
  color: var(--text-default);

  &.selected-amount,
  &:hover, &:focus {
    background-color: var(--accent-brand-subdued);
    border: 2px solid var(--accent-brand);
    color: var(--text-primary);
    font-weight: 600;
  }

  &.soldout {
    filter: grayscale(1);
    color: var(--text-primary);
  }
}

.panel-price {
  text-align: center;
}

.price {
  font-size: var(--heading-xs-font-size);
  line-height: var(--heading-xs-line-height);
  font-weight: 900;
  color: var(--text-primary);
}

.show-panel {
  position: fixed;
  z-index: 110;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--spacing-500);
  background: linear-gradient(0deg, var(--white) 0%, rgba(255, 255, 255, 0.00) 100%);

  button {
    max-width: 450px;
    margin: 0 auto;
  }
}
