@import 'styles/breakpoints';

$drawer-max-width: 450px;

.drawer {
  position: fixed;
  z-index: 110;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: auto;
  max-height: 100vh;
  @supports (height: 100dvh) {
    max-height: 100dvh;
  }

  top: auto;
  right: auto;
  bottom: 0;
  left: 0;

  .drawer-header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: var(--spacing-500);
    gap: var(--spacing-500);
    border-bottom: 1px solid var(--neutral-300);
    background-color: var(--white);
    margin-top: var(--spacing-500);
    border-top-left-radius: var(--spacing-500);
    border-top-right-radius: var(--spacing-500);
    max-width: $drawer-max-width;
    width: 100%;

    &.with-image {
      overflow: hidden;
    }

    &.with-extra {
      justify-content: space-between;
    }

    &.with-image {
      height: 240px;
      flex-shrink: 0;

      .header-img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        object-fit: cover;
      }

      .drawer-close {
        position: fixed;
      }
    }

    .drawer-close {
      position: relative;
      z-index: 5;
      color: var(--neutral-400);
      cursor: pointer;
      background-color: #e7e8f0;
      min-height: 32px;
      min-width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      aspect-ratio: 1/1;

      svg {
        color: #abaec8;
      }
    }
  }
}

.drawer-content {
  flex-grow: 1;
  padding: var(--spacing-500);
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  max-width: $drawer-max-width;
  width: 100%;

  .title {
    font-size: var(--heading-sm-font-size);
    line-height: var(--heading-sm-line-height);
    margin-bottom: var(--spacing-500);
  }
}
